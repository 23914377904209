import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import CurrencyFlag from '../countries/CurrencyFlag';
import BorderLinearProgress from '../progress/BorderLinearProgress';
import { numberToMoney } from '../../helpers';
import CreditLineTooltip from '../tooltips/CreditLineTooltip';
import { EditCreditLineButton } from '../buttons';
import { CREDIT_LINE_MASTER_ENTITIES } from '../../graphql/customers/queries';

const CreditLinesBarCell = ({ creditLines, masterEntityName, currencies, isEditable }) => {
  const creditLinesByCurrency = creditLines.reduce((acc, creditLine) => {
    acc[creditLine.currency?.isoCode] = creditLine;
    return acc;
  }, {});

  const allCreditLines = currencies.map((currency) => {
    const existingCreditLine = creditLinesByCurrency[currency.isoCode];

    return existingCreditLine || {
      id: `missing-${currency.isoCode}`,
      creditUsed: 0,
      limitAmount: 0,
      currency: { isoCode: currency.isoCode },
    };
  });

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      {allCreditLines.map((creditLine) => {
        if (creditLine.id.startsWith('missing-')) {
          return (
            <Stack key={creditLine.id} direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
              <CurrencyFlag isoCode={creditLine.currency.isoCode} />
              <Typography sx={{ textAlign: 'center', flex: 1 }}>Empresa sin Línea de Crédito</Typography>
            </Stack>
          );
        }

        const rate = creditLine && (creditLine?.creditUsed || creditLine?.limitAmount)
          ? creditLine.creditUsed / creditLine.limitAmount
          : 0;
        const value = Math.min(rate, 1);

        const currency = creditLine?.currency?.isoCode;
        const creditUsed = numberToMoney(creditLine?.creditUsed, currency);
        const lineAmount = numberToMoney(creditLine?.limitAmount, currency);

        const availableAmount = Math.max(creditLine.limitAmount - creditLine.creditUsed, 0);
        const totalAmount = numberToMoney(availableAmount, currency);

        return (
          <Stack key={creditLine.id} direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>
            <CurrencyFlag isoCode={creditLine.currency.isoCode} />
            <Tooltip
              title={(
                <CreditLineTooltip
                  totalCredit={lineAmount}
                  creditUsed={creditUsed}
                  available={totalAmount}
                  isoCode={currency}
                />
              )}
              componentsProps={{
                tooltip: {
                  sx: {
                    borderRadius: '8px',
                    bgcolor: 'grey.900',
                    '& .MuiTooltip-arrow': {
                      color: 'grey.900',
                    },
                  },
                },
              }}
              arrow
            >
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={value * 100}
                  sx={{ width: '100%' }}
                />
              </Box>
            </Tooltip>
            {isEditable && (
              <EditCreditLineButton
                creditLine={creditLine}
                masterEntityName={masterEntityName}
                query={CREDIT_LINE_MASTER_ENTITIES}
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

CreditLinesBarCell.propTypes = {
  creditLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      creditUsed: PropTypes.number,
      limitAmount: PropTypes.number,
      currency: PropTypes.shape({
        isoCode: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  masterEntityName: PropTypes.string,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      isoCode: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isEditable: PropTypes.bool,
};

CreditLinesBarCell.defaultProps = {
  creditLines: [],
  isEditable: false,
  masterEntityName: '',
};

export default CreditLinesBarCell;
